<template>
  <div class="landing-hero-banner">
    <UiImg
      :image="image"
      decorative
      :wrapper-index="index"
      class="landing-hero-banner__background"
    />

    <div class="landing-hero-banner__wrapper content-container-template">
      <T3HtmlParser
        v-if="bodytext"
        :content="bodytext"
        class="landing-hero-banner__above"
      />

      <UiHeader
        :header="header"
        :subheader="subheader"
        :header-layout="headerLayout"
        :header-size="headerSize"
        class="landing-hero-banner__heading"
      />

      <div
        v-if="displayCTA"
        class="landing-hero-banner__cta"
      >
        <ButtonBase :button="button" />

        <ButtonBase
          v-if="customButtonProps"
          :button="customButtonProps"
          class="landing-hero-banner__custom-btn"
        >
          <span
            v-if="customButtonProps.label"
            class="landing-hero-banner__custom-btn-label"
          >
            {{ customButtonProps.label }}
          </span>

          {{ customButtonProps.content }}
        </ButtonBase>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ButtonBase, UiHeader, UiImg } from '~ui/components'
import type { UiCeCaseStudyBannerProps } from '~ui/types'

const props = defineProps<UiCeCaseStudyBannerProps>()

const displayCTA = computed(() => props.button.content || props.tagList)

const customButtonProps = computed(() => {
  if (!props.tagList.length) return null

  const { buttonLabel, link, name } = props.tagList[0]

  return { link, label: buttonLabel, content: name }
})
</script>

<style lang="scss">
.landing-hero-banner {
  position: relative;
  padding-block: rem(32px) rem(48px);
  box-shadow: 0 1px 0 rgba(color(white), 16%);
  overflow: hidden;
  isolation: isolate;

  @include media-query(sm) {
    padding-block: rem(80px);
  }

  &__background {
    position: absolute;
    top: 50%;
    height: 100%;
    width: 100%;
    translate: 0 -50%;
    z-index: z-index(below);
    pointer-events: none;
    object-fit: cover;
    scale: 1.15;

    @include media-query(max sm) {
      scale: 2;
    }
  }

  &__wrapper {
    display: grid;

    @include media-query(sm) {
      justify-items: center;
    }
  }

  &__above {
    position: relative;
    width: fit-content;
    border: rem(1.4px) solid rgba(color(white), 12%);
    border-radius: em(4px);
    padding-block: em(10px);
    padding-inline: em(46px) em(18px);

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: rem(16px);
      width: rem(16px);
      background-color: color(primary);
      left: em(14px);
      top: 50%;
      translate: 0 -50%;
    }

    & * {
      margin: 0;
    }
  }

  &__heading.ui-header {
    & > * {
      text-align: left;

      @include media-query(sm) {
        text-align: center;
      }
    }

    & > .ui-header__title {
      font-size: rem(52px);
      margin-block: rem(24px);
      font-weight: 600;
    }

    & > .ui-header__subtitle {
      font-size: rem(18px);
      font-weight: 500;
      color: rgba(color(white), 80%);
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    gap: rem(20px) rem(24px);
    margin-top: rem(48px);

    @include media-query(sm) {
      flex-direction: row-reverse;
    }

    & a {
      margin: 0;

      @include media-query(max sm) {
        width: 100%;
      }

      & > span {
        height: rem(64px);
        padding: em(20px) em(32px);
      }
    }
  }

  &__custom-btn {
    &-label {
      font-size: 12px;
      padding: em(2px) em(4px);
      margin-right: rem(12px);
      background-color: color(primary);
      border-radius: 1px;
    }

    background-color: transparent;

    & > span {
      background-color: transparent;
    }

    &:is(:hover, :focus-visible) > span {
      background-color: rgba(color(white), 3%);
    }

    &:active > span {
      background-color: rgba(color(white), 6%);
    }
  }
}
</style>
